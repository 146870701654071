var Airtable = require("airtable");

// API_KEY = "key8zCaRvKaGKZ0rP";
let BASE_ID = "appppWf6pgb7Nkt1J";
let PERSONAL_ACCESS_TOKEN = "patqFH8vnDzJygmLi.c6f0b8d7d41e6a042e94e99ab795e07bf875583f31902e057c8377b0ea8a70e1";

Airtable.configure({
    apiKey: PERSONAL_ACCESS_TOKEN
});

var base = Airtable.base(BASE_ID);

export default base;