import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap';
import { ContentPage } from './ContentPage';
import headshot from  '../assets/headshot.jpg';
import base from '../api/base';
import '../style/Biodata.css';

const Biodata = () => {

    document.title = "Biodata";
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([])

    useEffect(() => {
        base("biodata")
            .select({ view: "all" })
            .eachPage((records, fetchNextPage) => {
                setItems(records);
                setLoading(false);
                fetchNextPage();
            })
    }, [])

    return (
        <ContentPage>
            <Container className="biodata-container" fluid>
                <Row className="biodata-row">
                    <Col className="biodata-col" md="auto">
                        <Image fluid roundedCircle src={headshot} />
                    </Col>
                    {loading ? (
                        <Col className="biodata-col spinner-col">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Col>
                    ) : (
                        <Col className="biodata-col" md="auto" >
                            {items.filter(item => item.fields.hide == null || items.fields.hide === false).map(item => (
                                <p className="biodata-content">
                                <strong className="biodata-rowName">{item.fields.row}: {' '}</strong>
                                {item.fields.content}
                                </p>
                            ))}
                        </Col>
                    )}
                </Row>
            </Container>
        </ContentPage>
    );
};

export default Biodata;